<template>
  <div class="chapterDetail">
    <div v-show="!isCard">
      <examinationHeader ref="examinationHeader" :showAnalysis="true" :paperAnswerTime="paperAnswerTime" :id="id" @headerClick="headerClick"></examinationHeader>
      <van-loading class="loading_bg" type="spinner" v-show="activeLoad"/>
      <chapterDetailCom ref="chapterDetailCom" :AnswerList="AnswerList" :isNext="isNext" @allEventFn="allEventFn"
                        :fontType="fontType" :isAnaly="isAnaly_val"></chapterDetailCom>
    </div>
    <div v-if="isCard">
      <answerCard @hideCard="hideCard" @headerClick="headerClick"></answerCard>
    </div>
  </div>
</template>

<script>

import {getTestPaperSubjectList} from "@/api/questionBank.js";
import chapterDetailCom from '../chapter/chapterDetail.vue';
import examinationHeader from '../examinationHeader/examinationHeader.vue';
import answerCard from '../answerCard/answerCard';
import { save_subject ,assignment_paper} from '@/api/chapter.js';
export default {
  components: {
    chapterDetailCom,
    examinationHeader,
    answerCard
  },
  data() {
    return {
      AnswerList: {},
      isNext: false,
      isCard: false,
      fontType: 'in',
      isAnaly_val:'no',
      paperAnswerTime:'',
      id:'',
      activeLoad:true
    }
  },
  name: 'chapterExercisesDetail',
  created() {
    this.init();
    this.$store.commit('setQuestionSwipeIndex',{index:-1});
  },
  methods: {
    init() {
      this.activeLoad = true;
      let type = this.$route.query.type==='rest'?2:1;
      let param = {
        nodeId: this.$route.query.id,
        nodeType: 1,
        type: type
      };
      this.id = this.$route.query.id;
      
      getTestPaperSubjectList(param).then((res) => {
        this.activeLoad = false;
        this.AnswerList = res.data.data;
        this.AnswerList.paperSubjectList = res.data.data.paperChapterSubjectList;
        this.paperAnswerTime = res.data.data.paperAnswerTime;
        this.$refs.chapterDetailCom.setSwiptTo(parseInt(this.AnswerList.completeNumber));
      })

    },
    hideCard(item) {
      this.isCard = false;
      if(item) {
        this.$refs.chapterDetailCom.setSwiptTo(parseInt(item.index));
      }
    },
    getAnswerTimeLength(times){
        let arys = times.split(":");
        let time1  = parseInt(arys[0])*60*60;
        let time2  = parseInt(arys[1])*60; 
        let time3  = parseInt(arys[2]);
        return time1+time2+time3; 
    },
    allEventFn(typeObj,res,index){
      if(typeObj.type=='radio'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        save_subject(answerTimeLength,'1',res.subjectId,res.answerId,"").then((res)=>{
              console.log("radio",res);
        });

      }else if(typeObj.type =='checkbox'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        res.map((item)=>{ answerIds.push(item.answerId) });
        save_subject(answerTimeLength,'1',res[0].subjectId,answerIds,"").then((res)=>{
            console.log("checkbox",res);
        })

      }else if(typeObj.type =='indeter'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        ansList.map((item)=>{ answerIds.push(item.answerId) });
        save_subject(answerTimeLength,'1',ansList[0].subjectId,answerIds,"").then((result)=>{
            console.log("indeter",result);
        })
      }else if(typeObj.type =='cal'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        save_subject(answerTimeLength,'1',ansList.subjectId,"",ansList.doneAnswer).then((res)=>{
              console.log("cal",res);
        });
      }
    },
    headerClick(item) {
     
      if (item.type === 'automaticNext') {
        // 自动下一题
        this.isNext = item.value;
      } else if (item.type === 'fontSize') {
        // 切换字体大小
        this.fontType = item.value;
      } else if (item.type === 'hand') {
        // 交卷
        assignment_paper(this.$route.query.id,1).then((res)=>{
          if(res.data.code == 0){
            this.$refs.examinationHeader&&this.$refs.examinationHeader.hideHand();
              //答题报告
            if(!item.isJump) {
              this.$router.push({
                path:"/answerReport",
                query:{
                  id:this.$route.query.id,
                  path:"/chapterExercisesDetail",
                  type: 'chapterExercises'
                }
              })
            }
          }
        })
        
      } else if (item.type === 'stop') {
        // 停止做题
        this.$refs.examinationHeader.hideHand();
      } else if (item.type === 'answerCard') {
        // 答题卡
        this.isCard = true;
      } else if (item.type === 'analysis') {
        // 答案解析
        let indexs = this.$refs.chapterDetailCom.getCurnet();
        this.$store.commit('setQuestionSwipeIndex',{index:indexs});
        this.isAnaly_val = 'yes';

      }
    }

  }
}
</script>
<style lang="less" scoped>
.chapterDetail {
  width: 100%;
  background: #f5f5f5;
}
.loading_bg{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
